import React, { Component } from 'react';


class widgetHelp extends Component{
	render(){
		return(
			<>
			</>
		);
	}
}

export default widgetHelp;