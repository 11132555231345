import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class ChooseUsSection extends Component{
	render(){
		return(
			<>
				
			</>
		);
	}
}

export default ChooseUsSection;