import React, { Component } from 'react';

import { Link } from 'react-router-dom';

// Images
import pic4 from "../../images/gallery/pic4.jpg";
import blogGridPic1 from "../../images/blog/grid/pic1.jpg";
import blogGridPic2 from "../../images/blog/grid/pic2.jpg";
import blogGridPic3 from "../../images/blog/grid/pic3.jpg";
import blogGridPic4 from "../../images/blog/grid/pic4.jpg";
import blogGridPic5 from "../../images/blog/grid/pic5.jpg";
import blogGridPic6 from "../../images/blog/grid/pic6.jpg";
import blogGridPic7 from "../../images/blog/grid/pic4.1.jpg"
import authorThumbPic1 from "../../images/testimonials/pic1.jpg";
import authorThumbPic2 from "../../images/testimonials/pic2.jpg";
import authorThumbPic3 from "../../images/testimonials/pic3.jpg";
import authorThumbPic4 from "../../images/testimonials/pic4.jpg";
import authorThumbPic5 from "../../images/testimonials/pic5.jpg";
import authorThumbPic6 from "../../images/testimonials/pic6.jpg";

class LatestBlogSection extends Component{
	render(){
	
		// const content = [
		// 	{ 
		// 		thumb: blogGridPic3,
		// 		//authorThumb: authorThumbPic1,
		// 		authorName: "Стъпка 1",
		// 		title: "Избор на Автомобил",
		// 		blogText: "Изисквания: Марка, Модел, Година на производство, Технически характеристики и т.н. Съобразете бюджета си, който включва не само стойността на автомобила 20% до 30% по-евтино от пазарната цена в България, но и всички допълнителни разходи като ремонт, обслужване, технотест и регистрация.",
		// 	},		
		// 	{ 
		// 		thumb: blogGridPic1,
		// 		//authorThumb: authorThumbPic2,
		// 		authorName: "Стъпка 2",
		// 		title: "Участие на Аукцион",
		// 		blogText: "Предварителен Депозит: За да участвате в аукциона, трябва да внесете депозит 15% от стойността на автомобила, не по палко от 1000лв. Участие на Живо и Спечелване: Можете да участвате на живо в аукциона или да използвате онлайн платформа. След спечелване на мпс от аукцион се скючва договор за посредничество и се издават фактури за заплащане.",
		// 	},
		// 	{ 
		// 		thumb: blogGridPic5,
		// 		//authorThumb: authorThumbPic3,
		// 		authorName: "Стъпка 3",
		// 		title: "Транспорт",
		// 		blogText: "Сухопътен транспорт автомобилът се превозва с камион от аукциона до близко пристанище. Морски транспорт автомобилът се транспортира по море от пристанището в САЩ до Ротердам. Автовоз след обмитяване, автомобилът се транспортира до България с автовоз.",
		// 	},
		// 	{ 
		// 		thumb: blogGridPic2,
		// 		//authorThumb: authorThumbPic4,
		// 		authorName: "Стъпка 4",
		// 		title: "Обмитяване",
		// 		blogText: "Плащане на Мито и ДДС: При влизане в Европейския съюз, плащате мито и ДДС според стойността на автомобила и договорените ставки.	Разтоварване и Такси за Обслужване: Автомобилът се разтоварва, след което плащате такси за обслужване на пристанището.",
		// 	},
		// 	{ 
		// 		thumb: blogGridPic4,
		// 		//authorThumb: authorThumbPic5,
		// 		authorName: "Стъпка 5",
		// 		title: "Получаване",
		// 		blogText: "Взимане на Автомобила: Вие или вашият представител вземате автомобила от нашия паркинг в България. Предаване на Документите и Плащане: Предавате всички необходими документи и правите последните плащания съгласно договора, след което автомобилът става ваш собственост.",
		// 	}			
		// ]

		return(
			<>
				<section className="section-area section-sp1">
					   <div className="container">
							<div className="row">
								<div className="col-md-12 col-lg-12 col-xl-12 mb-30 mb-md-50">
									<div className="row">
										<div className="col-12">
											<div className="blog-card blog-list list-large style-1 bg-white shadow">													
												<div className="post-info">
													<h4 className=""><Link className="text-primary" to="/booking">Стъпка 1</Link></h4>														
													<h3 className="post-title"><Link to="/booking">Избор на Автомобил</Link></h3>
													<div className="post-content">
													{/* className="justify-p" */}
														<p>
															Изисквания: марка, модел, година, технически характеристики и т.н. 																
															Бюджетът трябва да бъде съобразен, не само със стойността на автомобила, който е с 20% до 30% под пазарната цена в Европа, но и за всички допълнителни разходи, като: ремонт, обслужване, технотест и регистрация.
														</p>
													</div>
												</div>
												<div className="post-media">
													<Link to="/booking"><img src={blogGridPic3} alt=""/></Link>
												</div>
											</div>
											<div className="blog-card blog-list list-large style-1 bg-white shadow">													
												<div className="post-info">
													<h4 className=""><Link className="text-primary" to="/booking">Стъпка 2</Link></h4>														
													<h3 className="post-title"><Link to="/booking">Участие на Аукцион</Link></h3>
													<div className="post-content">
														<p>
															Внася се депозит, 15% от стойността на автомобила. След спечелване се скючва договор за посредничество и се издават фактури за плащане.															
														</p>
													</div>
												</div>
												<div className="post-media">
													<Link to="/booking"><img src={blogGridPic1} alt=""/></Link>
												</div>
											</div>
											<div className="blog-card blog-list list-large style-1 bg-white shadow">													
												<div className="post-info">
													<h4 className=""><Link className="text-primary" to="/booking">Стъпка 3</Link></h4>														
													<h3 className="post-title"><Link to="/booking">Транспорт</Link></h3>
													<div className="post-content">
														<p>
															Сухопътен транспорт от аукцион до пристанище. Морски транспорт по море от САЩ до Ротердам. Автовоз до България. Средно доставката отнема от 2 до 3 месеца.
														</p>
													</div>
												</div>
												<div className="post-media">
													<Link to="/booking"><img src={blogGridPic5} alt=""/></Link>
												</div>
											</div>
											<div className="blog-card blog-list list-large style-1 bg-white shadow">													
												<div className="post-info">
													<h4 className=""><Link className="text-primary" to="/booking">Стъпка 4</Link></h4>														
													<h3 className="post-title"><Link to="/booking">Обмитяване</Link></h3>
													<div className="post-content">
														<p>
															Обмитяване на мито и ДДС при влизане в Европейския съюз, разтоварване, митнически такси и обслужване на пристанището.
														</p>
													</div>
												</div>
												<div className="post-media">
													<Link to="/booking"><img src={blogGridPic7} alt=""/></Link>
												</div>
											</div>	
											<div className="blog-card blog-list list-large style-1 bg-white shadow">													
												<div className="post-info">
													<h4 className=""><Link className="text-primary" to="/booking">Стъпка 5</Link></h4>														
													<h3 className="post-title"><Link to="/booking">Финализиране</Link></h3>
													<div className="post-content">
														<p>
															Предаване на автомобила и всички необходими документи на собственика и плащане на остатъка от сумата по договора.															 
														</p>
													</div>
												</div>
												<div className="post-media">
													<Link to="/booking"><img src={blogGridPic4} alt=""/></Link>
												</div>
											</div>	


											{/* {content.map((item, index) =>(
												<div key={index} className="blog-card blog-list list-large style-1 bg-white shadow">													
													<div className="post-info">
														<h4 className=""><Link className="text-primary" to="/booking">{item.authorName}</Link></h4>
														<h3 className="post-title"><Link to="/booking">{item.title}</Link></h3>
														<div className="post-content">
															<p className="mb-0">{item.blogText}</p>
														</div>
													</div>
													<div className="post-media">
														<Link to="/booking"><img src={item.thumb} alt=""/></Link>
													</div>
												</div>
											))} */}
										</div>
									</div>
								</div>								
							</div>
						</div>
					</section>

				{/* <section className="section-area section-sp2 bg-gray ov-hidden">
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Стъпка 1</h6>
							<h2 className="title mb-0">Определяне на Бюджет</h2>
							<p>Ние ще Ви помогнем в избора на автомобил спрямо Вашите изисквания и бюджет, както и ще Ви дадем полезни съвети!
								Всички разходи ще бъдат изчислени като закупуването, транспорта и доставката.
							<div className="secondary-menu">
								<Link to="/booking" className="btn btn-primary ms-3">
								Безплатна заявка за МПС!
								</Link>
							</div>
							</p>
						</div>
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Стъпка 2</h6>
							<h2 className="title mb-0">Договор и Участие на Търг</h2>
							<p>След вашия избор ще изготвим договор за нашите услуги, ще проверим историята на автомобила и ще участваме на търг за вашето превозно средство.
								Заедно с договора се заплаща и депозит.
							</p>
						</div>
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Стъпка 3</h6>
							<h2 className="title mb-0">Заплащане и Транспорт</h2>
							<p>След като спечелим вашият автомобил следва заплащане на пълната стойност на МПС-то както и всички останали разходи до 24ч, съгласно Вашия бюджет.  </p>
						</div>
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Стъпка 4</h6>
							<h2 className="title mb-0">Доставка</h2>
							<p>В зависимост от <b><u>локацията</u></b> и <b><u>дестинацията</u></b> на МПС-то ще определим времето за доставка.Средно между 1 и 3 месеца.
							След пристигането на автомобила Вие ще го получите с всички нужни документи. </p>
						</div>
						<div className="heading-bx text-center">
							<h6 className="title-ext text-primary">Стъпка 5</h6>
							<h2 className="title mb-0">Ремонт и Финализиране</h2>
							<p>Ако закупенто МПС има нужда от ремонт ние можем да го поправим и обслужим. Освен това можем да се погрижим бързо и безпроблемно изваждане на задължителния ТехноТест.
								След изваждането на документа Вие сте готови за регистрация!
							 <div className="secondary-menu">
								<Link to="/service-1" className="btn btn-primary ms-3">
										Всички наши услуги
								</Link>
							</div>
							 
							 
							  </p>
						</div>
			
					</div>
				</section> */}
				
			</>
		);
	}
}

export default LatestBlogSection;