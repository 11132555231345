import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import icon1 from "../../../images/icon/contact/skin2/icon1.png"
import icon2 from "../../../images/icon/contact/skin2/icon2.png"
import icon3 from "../../../images/icon/contact/skin2/icon3.png"

class widgetContact extends Component{
	render(){
		return(
			<>
				<div className="widget">
					<h5 className="widget-title">Свържете се с нас</h5>
					<ul className="contact-infolist">
						<li>
							<img src={icon1} alt=""/>
							<h6 className="contact-title">Телефон за контакт</h6>
							<p><a href="tel:+359 876 333 149">0876 333 149</a> <br/> <a href="tel:+359 876 333 249">0876 333 249</a></p>
						</li>
						<li>
							<img src={icon2} alt=""/>
							<h6 className="contact-title">Email Address</h6>
							{/* <Link href="mailto: American01Auto@gmail.com">American01Auto@gmail.com</Link><br/> */}
							<a href = "mailto: American01Auto@gmail.com">American01Auto@gmail.com</a><br/>				
						</li>
						<li>
							<img src={icon3} alt=""/>
							<h6 className="contact-title">Address</h6>
							<p>Велико Търново, бул. Бъргария</p>
						</li>
					</ul>
				</div>
				
			</>
		);
	}
}

export default widgetContact;