import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Elements
import WidgetBrochure from "../elements/widget/widget-brochure";
import WidgetHelp from "../elements/widget/widget-help";

// Images
import bnrImg from "../../images/banner/bnr5.jpg";
import Pic1 from "../../images/service-section/pic1.jpg";
import Pic2 from "../../images/service-section/pic2.jpg";

class ServiceEngineDiagnostics extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-5">
									<aside className="sticky-top pb-1">
										
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/service-engine-diagnostics"><span>Диагностика</span><i className="fa fa-angle-right"></i> </Link></li>
												<li><Link to="/service-lube-oil-and-filters"><span>Масло и филтри</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-belts-and-hoses"><span>Belts and Hoses</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-air-conditioning"><span>Air Conditioning</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-brake-repair"><span>Brake Repair</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-tire-and-wheel-services"><span>Tire and Wheel Services</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										
										<WidgetBrochure />
										
										<WidgetHelp />
										
									</aside>
								</div>								

								<div className="col-lg-8 col-md-7">
									<div className="row mb-15">
										<div className="col-md-6 mb-15">
											<div className="ttr-media">
												<img src={Pic1} className="imghight" alt=""/>
										
											</div>
										</div>
										<div className="col-md-6 mb-15">
											<div className="ttr-media">
											<img src={Pic2} className="imghight" alt=""/>
											</div>
										</div>
									</div>
									
									<div className="clearfix">
										<div className="row">
											<div className="col-lg-6">
												<ul className="list-check-squer mb-0">
													<li>Диагностика с Launch / Star или VAG Com в зависимост от автомобила)</li>
													<li>Прочитане на живи данни</li>
													<li>Кодиране на модули и части</li>
												</ul>
											</div>
											<div className="col-lg-6">
												<ul className="list-check-squer mb-0">
													<li>Тестване на компоненти и датчици</li>
													<li>DPF/EGR/TMPS/Brake reset и други</li>
													<li>Варянтно кодиране и отключване на екстри</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				
				</div>
				
			</>
		);
	}
}

export default ServiceEngineDiagnostics;