import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Elements
import WidgetBrochure from "../elements/widget/widget-brochure";
import WidgetHelp from "../elements/widget/widget-help";

// Images
import bnrImg from "../../images/banner/bnr3.jpg";
import Pic3 from "../../images/service-section/pic3.jpg";
import Pic6 from "../../images/service-section/pic6.jpg";
import Pic8 from "../../images/service-section/pic8.jpg";

class ServiceBeltsAndHoses extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Belts and Hoses</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-5">
									<aside className="sticky-top pb-1">
										
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/service-engine-diagnostics"><span>Диагностика</span><i className="fa fa-angle-right"></i> </Link></li>
												<li><Link to="/service-lube-oil-and-filters"><span>Масло и филтри</span><i className="fa fa-angle-right"></i></Link></li>
												<li className="active"><Link to="/service-belts-and-hoses"><span>Цялостен Преглед</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-air-conditioning"><span>Air Conditioning</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-brake-repair"><span>Brake Repair</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-tire-and-wheel-services"><span>Tire and Wheel Services</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										
										<WidgetBrochure />
										
										<WidgetHelp />
										
									</aside>
								</div>
								<div className="col-lg-8 col-md-7">
									<div className="row">
										<div className="col-lg-6">
											<div className="ttr-media mb-30">
												<img src={Pic3} className="imghight" alt=""/>
											</div>
											<div className="ttr-media mb-30">
												<img src={Pic6} className="imghight" alt=""/>
											</div>
										</div>
										<div className="col-lg-6">
											<div className="ttr-media mb-30">
												<img src={Pic8} alt=""/>
											</div>
										</div>
									</div>
									
									
								</div>
							</div>
						</div>
					</div>
				
				</div>
				
			</>
		);
	}
}

export default ServiceBeltsAndHoses;