import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Faq1 from '../elements/faq-1'

// Images
 import bnrImg from "../../images/banner/bnr1.jpg";
 import bnrImg2 from "../../images/background/bg1.png";

class Booking extends Component{
		
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Запитване</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Запитване</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1" style={{backgroundImage: "url("+bnrImg2+")"}}>
						<div className="container">
							<div className="row">
								<div className="col-lg-2"></div>
								<div className="col-lg-8 col-md-7 mb-30 mb-md-50">
									<form className="booking-form">
										<div className="row">
											<div className="col-lg-4">
											<div className="form-group">
													<h6 className="form-title">Марка</h6>
													<div className="input-group">
													<input name="name" type="text" className="form-control" placeholder="Избери" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
												<h6 className="form-title">Модел</h6>
													<div className="input-group">
														<input name="name" type="text" className="form-control" placeholder="Избери" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
											<div className="form-group">
													<h6 className="form-title">Година</h6>
													<div className="input-group">
														<input type="number" className="form-control" defaultValue="2008"/>
													</div>
												</div>	
											</div>
											
											<div className="col-lg-4">
											<div className="form-group">
													<h6 className="form-title">Ценови Диапазон</h6>
													<div className="input-group">
														<select className="form-control">
															<option defaultValue="">Лева</option>
															<option defaultValue="na">5000 - 10,000</option>
															<option defaultValue="na">10,000 - 15,000</option>
															<option defaultValue="Lexus">15,000 - 20,000</option>
															<option defaultValue="Lincoln">20,000 - 25,000</option>
															<option defaultValue="Mazda">30,000 - 40,000</option>
															<option defaultValue="Mercury"> над 40,000 </option>
														</select>
													</div>
												</div>
											</div>

											<div className="col-lg-4">
											<div className="form-group">
													<h6 className="form-title">Трансмисия</h6>
													<div className="input-group">
														<select className="form-control">
															<option defaultValue="">Избери</option>
															<option defaultValue="na">Ръчна</option>
															<option defaultValue="na">Автоматична</option>
														</select>
													</div>
												</div>
											</div>

											
											<div className="col-lg-4">
											<div className="form-group">
													<h6 className="form-title">Двигател</h6>
													<div className="input-group">
														<select className="form-control">
															<option defaultValue="">Бензин</option>
															<option defaultValue="na">Дизел</option>
															<option defaultValue="na">Друго</option>
														</select>
													</div>
												</div>
											</div>
										
											<div className="col-lg-12">
												<div className="form-group">
													<h6 className="form-title">Данни</h6>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="name" type="text" className="form-control" placeholder="Име" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="phone" type="text" placeholder="Телефонен Номер" className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<input name="email" type="email" placeholder="Email" className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<input name="link" type="text" placeholder="Линк на автомобил избран от Вас (Copart/IAAI и др)" className="form-control" />
													</div>
												</div>
											</div>
											
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<textarea name="message" rows="4" className="form-control" required=""  placeholder="Съобщение"></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" defaultValue="Submit" className="btn btn-primary btn-lg"> Изпрати Запитване </button>
											</div>
										</div>
									</form>
								</div>
								<div className="col-lg-2"></div>
							</div>
						</div>
					</section>

					<Faq1 />
					
				</div>
				
			</>
		);
	}
}

export default Booking;