import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg2 from "../../images/background/bg2.png";

class ServicesSection extends Component{
	render(){
		return(
			<>
				<section className="section-area  service-wraper1 section-sp2" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat", backgroundPosition: "right top"}}>
					<div className="container">
						<div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
							<div className="clearfix">
								<h2 className="title mb-0">Услуги които предлагаме</h2>
							</div>
							{/* <div className="clearfix mt-md-20">
								<Link to="/services" className="btn btn-outline-light">Виж Всички</Link>
							</div> */}
						</div>
						<div className="row spno">
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-fuel-1"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Диагностика</h5>
										<p>Диагностика, Активация на модули, Variant-кодиране и други.</p>
										<Link to="/service-tire-and-wheel-services" className="btn-link">Прочети</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-brake"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Ремонт спирачна система</h5>
										<p>Ремонт и смяна на дискове и спирачки.</p>
										<Link to="/service-brake-repair" className="btn-link">Прочети</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-car-4"></i>
									</div>
									<div className="icon-content">
									<h5 className="ttr-tilte">Смяна Масло</h5>
										<p>Смяна на масло, филтри и всички консумативи.</p>
										<Link to="/service-lube-oil-and-filters" className="btn-link">Прочети</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-check-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Преглед</h5>
										<p>Преглед на автомобила на подемник за текущо състояние, проблеми и течове.</p>
										<Link to="/service-belts-and-hoses" className="btn-link">Прочети</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-battery-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Автомобилни акумулатори</h5>
										<p>Продажба на акумулатори с отстъпка на нашите клиенти.</p>
										<Link to="/service-tire-and-wheel-services" className="btn-link">Прочети</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-paint"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Автобояджийство и тенекеджийство</h5>
										<p>Предлагаме на клиентите закупили автомобили от нас ремонт на тяхните щети.</p>
										<Link to="/service-air-conditioning" className="btn-link">Прочети</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container active feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-repair-1"></i> 
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Автомобилни Гуми</h5>
										<p>Смяна на гуми и джанти. Реглаж и отстраняване на проблеми с TMPS датчици.</p>
										<Link to="/service-tire-and-wheel-services" className="btn-link">Прочети</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-engine"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Двигател</h5>
										<p>Диагностика, ремонт и обслужване.</p>
										<Link to="/service-engine-diagnostics" className="btn-link" >Прочети</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default ServicesSection;